export const cleanUrl = (url: string) => {
  try {
    const urlObj = new URL(url);
    let cleanedUrl = urlObj.hostname.replace(/^www\./, "");

    // Add path if it exists and isn't just "/"
    if (urlObj.pathname && urlObj.pathname !== "/") {
      // Remove trailing slash
      const path = urlObj.pathname.replace(/\/$/, "");
      cleanedUrl += path;
    }

    return cleanedUrl;
  } catch {
    // If URL parsing fails, do basic cleanup
    return url.replace(/^(https?:\/\/)?(www\.)?/, "");
  }
};
